rodijco.controller 'LogsController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, $location, httpService) ->

    scope.customers = {}
    scope.currentPage = if isNaN(routeParams.page) then 0 else routeParams.page
    scope.count = 0
    scope.limit = 10
    scope.totalPages = 0
    scope.showPages = 1
    scope.showHellip = false

    httpService.get "logs?page=#{scope.currentPage}", (response) ->
      if response.success
        scope.customers = response.data?.logs
        scope.count = response.data?.count

        rounded = Math.ceil(scope.count / scope.limit)
        rounded = if rounded > 0 then rounded - 1 else rounded
        scope.totalPages = [0..(rounded)]

        scope.showHellip = if rounded > (rounded - scope.showPages) then true else false

    scope.removeAll = () ->
      httpService.delete 'logs', (response) ->
        if response.success
          location.reload()

        console.log response
]
